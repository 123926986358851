import React from "react";
import { Wizards } from "./Wizards";
import { Answer } from "./Answer";
import { localStoragesKeys, findAnswerBySlug } from "../helper";
import { builds, createQuestion } from "../data/question";
import { header, list } from "../data/style";
import Finish from "./Finish";
import { Header } from "../header/header";
import { Button } from "../layour/button";

export default class Quiz extends React.Component {
  state = {
    questions: [],
    step: 0,
    wizard: "start",
    rent: "none",
    build: "none",
    result: [],
    disableNext: false,
    height: "auto"
  };

  buildQuestionHandler() {
    const questions =  createQuestion(this.state.build || "none");
    this.setState({questions});
  }

   

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.build !== "none" && prevState.build.slug !== this.state.build.slug) {
        this.buildQuestionHandler();
    }
  }

  handleClickNext = () => {
    const { result, step, build } = this.state;
    this.setState({ height: "0px" });
    let nextStep;
    if (result[step].answer === 1 && result[step].slug === "casing") {
        this.setState({wizard: "finish"});
    }
      if (step === 4 && build > 4) {
        nextStep = step + 2;
      } else {
        nextStep = step + 1;
      }
    // localStorage.removeItem(localStoragesKeys.step);
    // localStorage.setItem(localStoragesKeys.step, JSON.stringify(nextStep));
    this.setState({ step: nextStep });
    this.setState({ height: "auto" });
  };

  handleClickWizardNext = step => {
    this.setState({ height: "0px" });
    const nextStep = step;
    this.setState({ wizard: nextStep });
    this.setState({ height: "auto" });
    this.props.handlerRef();
  };

  handleChooseAnswer = idx => {
    const { result, step, questions } = this.state;
    result[step] = { answer: idx, slug: questions[step].slug };
    // localStorage.removeItem(localStoragesKeys.result);
    // localStorage.setItem(localStoragesKeys.result, JSON.stringify(result));
    this.setState({ result });
  };

  handleClickPrev = () => {
    let prevStep = this.state.step - 1;
    let wizard = "steps"
    if(!this.state.questions.length){
        prevStep = 0;
        wizard = "build";
    }
    this.setState({ step: prevStep, wizard });
  };

  handlerClearStep = () => {
    this.setState({ step: 0 });
    this.setState({ result: [] });
    this.setState({ build: "none" });
    this.setState({ rent: "none" });
    this.setState({ wizard: "start" });
  };

  handlerStep = step => {
    this.setState({ step });
  };

  handlerClearAll = () => {
   };

  handlerBuild = build => {
    this.setState({ build: build });
    localStorage.setItem(localStoragesKeys.build, JSON.stringify(build));
  };

  handlerRent = rent => {
    this.setState({ rent: rent });
    localStorage.setItem(localStoragesKeys.rent, JSON.stringify(rent));
  };

  renderBody = () => {
    const { step, questions, result, wizard, rent, build } = this.state;


    if (wizard === "start") {
      return (
        <div className="quiz-nadlan-wrapper quiz-nadlan-start text-center">
          <h2 className="quiz-nadlan-header">ברוכים הבאים ל-Price Analyzer</h2>
          <p></p>
          מחירון של משרדים במתחם BBC
          <p></p>
          חברת RECOM ו-BBC נדל"ן מעניקות לכם כלי שיעזור לכם להיות יעילים
          ומדויקים יותר בהשכרה או מכירה/רכישה של משרדים. <p></p>
          ענו על השאלות וקבלו דו"ח של רמת סיכון העִסקה שברצונכם לעשות. בנוסף
          קבלו המלצות שיעזרו לכם לבחור את האופציה הכי מתאימה לכם.
          <p></p>
          אנחנו תמיד לשירותכם, כי הצלחה שלכם – זאת הצלחה שלנו.
          <p></p>
          <small>
            ** השאלון והדו"ח נכתבו בלשון זכר מטעמי נוחות בלבד, אך הם מיועדים
            לנשים ולגברים כאחד.
          </small>
          <p></p>
          <Button
            onClick={() => this.handleClickWizardNext("saleRent")}
            className="quiz-nadlan-button quiz-nadlan-button-green start"
          >
            התחל >
          </Button>
        </div>
      );
    }

    if (wizard === "saleRent") {
      return (
        <>
        <Header/>
        <div className="col-12">
          <h2 style={header}>מחיר של איזו עסקה אתה מעוניין לבדוק? </h2>
          <ul style={list}>
            <li
              className={`quiz-nadlan-question ${
                this.state.rent === "true" ? "active" : "noActive"
              }`}
              onClick={() => this.handlerRent("true")}
            >
              <div>השכרה</div>
            </li>
            <li
              className={`quiz-nadlan-question ${
                this.state.rent === "false" ? "active" : "noActive"
              }`}
              onClick={() => this.handlerRent("false")}
            >
              <div>מכירה/רכישה</div>
            </li>
          </ul>
          <br />
          <div className="buttons-wrapper row-fluid">
            <div className=" col-md-3 col-xs-12 ">
              <Button
                onClick={() => this.handleClickWizardNext("start")}
                className="quiz-nadlan-button quiz-nadlan-button-orange"
              >
                {`הקודמת`}
              </Button>
            </div>
            <div className=" col-md-3 col-xs-12 ">
              <Button
                onClick={() => this.handleClickWizardNext("build")}
                className="quiz-nadlan-button quiz-nadlan-button-green"
                disabled={this.state.rent === "none"}
              >
                {`הבאה`}
              </Button>
            </div>
          </div>
        </div>
        </>
      );
    }

    if (wizard === "build") {
      return (
        <>
        <Header/>
        <div className="col-12">
          <h2 style={header}>באיזה בניין אתה מחפש? </h2>
          <ul style={list}>
            {builds
              .filter(build => {
                if (this.state.rent === "true") {
                  return true;
                } else {
                  return build.isOnlyRent === "false";
                }
              })
              .map((build, idx) => {
                const activeAnswer =
                  this.state.build.slug === build.slug ? "active" : "noActive";
                return (
                  <li
                    className={`quiz-nadlan-question ${activeAnswer}`}
                    key={idx}
                    onClick={() => this.handlerBuild(build)}
                  >
                    <div dangerouslySetInnerHTML={{ __html: build.name }} />{" "}
                  </li>
                );
              })}
          </ul>
          <br />
          <div className="buttons-wrapper row-fluid">
            <div className=" col-md-3 col-xs-12 ">
              <Button
                onClick={() => this.handleClickWizardNext("saleRent")}
                className="quiz-nadlan-button quiz-nadlan-button-orange"
              >
                {`הקודמת`}
              </Button>
            </div>
            <div className=" col-md-3 col-xs-12 ">
              <Button
                onClick={() => this.handleClickWizardNext("steps")}
                className="quiz-nadlan-button quiz-nadlan-button-green"
                disabled={this.state.build === "none"}
              >
                {`הבאה`}
              </Button>
            </div>
          </div>
        </div>
        </>
      );
    }

    if (step >= questions.length || !questions.length || wizard === "finish") {
      return (
        <Finish
          handlerPrev={this.handleClickPrev}
          onClear={this.handlerClearStep}
          handlerStep={this.handlerStep}
          handlerClearAll={this.handlerClearAll}
          answersResult={{ result, rent, build }}
        />
      );
    }

    const questionCurrent = questions[step];
    let { question, answers } = questionCurrent;
    const current =
      result[step] !== undefined && result[step] !== -1 ? result[step] : -1;
    if (questionCurrent.slug === "brutoNetto") {
      if (findAnswerBySlug(result, 'size') < 2) {
        answers = answers[0];
      } else {
        answers = answers[1];
      }
  }

    if (questionCurrent.slug === "finishLevel") {
      if (findAnswerBySlug(result, 'size') < 2) {
        answers = answers[0];
      } else {
        answers = answers[1];
      }
    }

    if (step >= 0 && wizard === "steps") {
      return (
        <div className="col-12">
          <Header/>
          <Wizards step={step} counts={questions.length} />
          <h2 style={header} dangerouslySetInnerHTML={{ __html: question }} />

          <Answer
            answers={answers}
            chooseAnswer={this.handleChooseAnswer}
            answerCurrent={current}
          />
          <br />
          <div className="buttons-wrapper row-fluid">
            <div className=" col-md-3 col-xs-12 ">
              <Button
                onClick={
                  step !== 0
                    ? this.handleClickPrev
                    : () => this.handleClickWizardNext("build")
                }
                className="quiz-nadlan-button quiz-nadlan-button-orange"
              >
                {`הקודמת`}
              </Button>
            </div>
            <div className=" col-md-3 col-xs-12 ">
              <Button
                onClick={this.handleClickNext}
                className="quiz-nadlan-button quiz-nadlan-button-green"
                disabled={current === -1}
              >
                {`הבאה`}
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return <div className="col-12">Problem</div>;
  };

  render() {
    const { questions } = this.state;

    if (!questions) return "Loading";

    return (
      <>
        {this.renderBody()}
      </>
    );
  }
}
