import React from 'react';
import './App.css';
import Quiz from './components/Quiz';

function App() {
  const appRef = React.useRef(null);

  const handlerRef = () => {
    appRef.current.scrollIntoView({block: "start", behavior: "smooth"});
  } 
  return (
    <div className="container-fluid wrapperNadlan"  ref={appRef}  id="app-price">
      <div className="">
        <div className="quiz-nadlan quizBadlan">
          <Quiz handlerRef={handlerRef} />
        </div>
      </div>
    </div>
  );
}

export default App;
