export const builds = [

    {
        name: "בסר2",
        isOnlyRent: "false",
        slug: 1,
        rent_price: [95, 90, 87, 85],
        sale_price: [18000, 17000, 16300, 15700],
        questions: [0, 1, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "בסר3",
        isOnlyRent: "false",
        slug: 2,
        sale_price: [20000, 19000, 18000, 17500],
        rent_price: [100, 95, 90, 90],
        questions: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "בסר4",
        isOnlyRent: "false",
        slug: 3,
        sale_price: [20000, 19000, 18000, 17500],
        rent_price: [100, 95, 90, 90],
        questions: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "הכשרת היישוב",
        isOnlyRent: "false",
        slug: 4,
        sale_price: [18000, 17000, 16100, 15700],
        rent_price: [95, 90, 87, 85],
        questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
        name: ` צ'מפיון `,
        isOnlyRent: "true",
        slug: 5,
        sale_price: [0, 0, 0, 0],
        rent_price: [0, 0, 0, 0],
        descriptionStart:"  מגדל שייך לחברה אחת והיא קובעת מדיניות של המשרדים", 
        options: "<h2>(ברמת גמר)</h2>", 
        description: "75-90",
        questions: [],
    },
    {
        name: "ווי טאוור",
        isOnlyRent: "false",
        slug: 6,
        sale_price: [18000, 17500, 16300, 15700],
        rent_price: [97, 90, 85, 85],
        questions: [0, 1, 2, 4, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "AFI קונקורד ",
        isOnlyRent: "true",
        slug: 7,
        sale_price: [0, 0, 0, 0],
        rent_price: [0, 0, 0, 0],
        
        descriptionStart:"  מגדל שייך לחברה אחת והיא קובעת מדיניות של המשרדים", 
        options: "<h2>(ברמת גמר)</h2>", 
        description: "60-70",
        questions: [],
    },
    {
        name: "בסר1",
        isOnlyRent: "false",
        slug: 8,
        rent_price: [95, 90, 87, 85],
        sale_price: [18000, 17000, 16300, 15700],
        questions: [0, 1, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "מגדל אלייד ",
        isOnlyRent: "true",
        slug: 9,
        sale_price: [0, 0, 0, 0],
        rent_price: [0, 0, 0, 0],
        descriptionStart:"  מגדל שייך לחברה אחת והיא קובעת מדיניות של המשרדים", 
        options: '<h2>(ברמת מעטפת עם תקציב בניה ממשכיר - 1,000 ₪ למ"ר ברוטו)</h2>', 
        description: "65-80",
        questions: [],
    },
    {
        name: "סטודיאו טאוור ",
        isOnlyRent: "true",
        slug: 10,
        sale_price: [0, 0, 0, 0],
        rent_price: [0, 0, 0, 0],
        descriptionStart:"  מגדל שייך לחברה אחת והיא קובעת מדיניות של המשרדים", 
        options: '<h2>(ברמת מעטפת עם תקציב בניה ממשכיר  - 1,500 ₪ למ"ר ברוטו)</h2>', 
        description: "65-75",
        questions: [],
    },
    {
        name: "בית נועה",
        isOnlyRent: "false",
        slug: 11,
        sale_price: [18000, 17500, 16300, 15700],
        rent_price: [97, 90, 85, 85],
        questions: [0, 1, 2, 4, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "בר כוכבא",
        isOnlyRent: "false",
        slug: 12,
        sale_price: [17000, 16500, 15500, 15500],
        rent_price: [88, 85, 82, 80],
        questions: [0, 1, 2, 4, 6, 7, 8, 9, 10, 11],
    },
    {
        name: "מגדלי קונקורד",
        isOnlyRent: "true",
        slug: 13,
        sale_price: [0, 0, 0, 0],
        rent_price: [75, 73, 70, 70],
        questions: [0, 1, 3, 7, 8, 9, 10, 11],
    },
    {
        name: "מגדלי ויטה",
        isOnlyRent: "false",
        slug: 14,
        sale_price: [12200, 12200, 12000, 11700],
        rent_price: [75, 73, 70, 70],
        questions: [0, 1, 8, 9, 10, 11],
    },
    {
        name: "בית נוח/בית דוד, או מה שדומה להם",
        isOnlyRent: "false",
        slug: 15,
        sale_price: [12400, 11900, 11800, 11400],
        rent_price: [65, 63, 63, 60],
        questions: [0, 1, 8, 9, 10, 11],
    },
];

export const questions = [
    // 0
    {
        "question": "מה גודל הברוטו של המשרד?",
        "slug": "size",
        "answers": [
            '30–80 מ"ר',
            '81–140 מ"ר',
            '141–260 מ"ר',
            '261+ מ"ר'
        ]
    },
    // 1
    {
        "question": "מהו יחס הברוטו-נטו?",
        "slug": "brutoNetto",
        "answers": [
            [
                'פחות/ שווה ל– 30%',
                'יותר מ- 31%'
            ],
            [
                'פחות/ שווה ל- 25%',
                'יותר מ- 26%'
            ]
        ]
    },
    // 2
    {
        "question": `האם ממ"ק (מרחב מוגן קומתי) <br/>כלול בשטח של המשרד?`,
        "slug": "mmk",
        "answers": [
            "לא, מחוץ למשרד",
            "כן, בתוך המשרד",
        ]
    },
    // 3
    {
        "question": "האם יש שירותים ציבוריים בקומה<br/> (נוסף על שירותי נכים)?",
        "slug": "wc",
        "answers": [
            "יש",
            "אין",
        ]
    },
    // 4
    {
        "question": "איזו כניסה יש למשרד?",
        "slug": "enter",
        "answers": [
            "ראשית (מול המעלית או קרובה מאוד אליה)",
            "משנית (דרך המסדרון)",
        ]
    },
    // 5
    {
        "question": "באיזו קומה נמצא המשרד?",
        "slug": "etage",
        "answers": [
            `אזור (Zone) עליון`,
            `אזור תחתון`,
        ]
    },
    // 6
    {
        "question": "איזה נוף יש מהמשרד?",
        "slug": "view",
        "answers": [
            `יפה ומיוחד`,
            "רגיל",
        ]
    },
    // 7
    {
        "question": "האם יש מרפסת במשרד?",
        "slug": "balcony",
        "answers": [
            `יש (פחות/שווה ל-30 מ"ר)`,
            `יש (יותר מ-30 מ"ר)`,
            `אין`,
        ]
    },
    // 8
    {
        "question": "האם המשרד ברמת מעטפת?",
        "slug": "casing",
        "answers": [
            "לא",
            "כן",
        ]
    },
    // 9
    {
        "question": "האם המשרד מתוכנן יעיל (מכיל הרבה חדרים בהשוואה לגודל הברוטו שבחרת)?",
        "slug": "countRoom",
        "answers": [
            "כן",
            "לא",
            "אחרת"
        ]
    },
    // 10
    {
        "question": "מהי רמת הגימור?",
        "slug": "finishLevel",
        "answers": [
            [
                'גבוהה (יותר מ-3,501 ₪ למ"ר ברוטו)',
                'בינונית (2,700–3,500 ₪ למ"ר)',
                'נמוכה (פחות מ-2,700 ₪ למ"ר)'
            ],
            ['גבוהה (יותר מ-3,300 ₪ למ"ר ברוטו)',
                'בינונית (2,500–3,300 ₪ למ"ר)',
                'נמוכה (פחות מ-2,500 ₪ למ"ר)'
            ]
        ]
    },
    // 11
    {
        "question": "האם המשרד מרוהט? ",
        "slug": "furnished",
        "answers": [
            "כן",
            "לא",
        ]
    },
];



export const createQuestion = (build) => {
    let res = questions;

    const buildQuestion = build.questions;
    if(build !== "none" && build.questions) {
        res = [];
        buildQuestion && buildQuestion.map( number => res.push(questions[number]));
    } else {
        res = [];
    }
        

    return res;

}