import React from 'react';
import { buildHtmlData } from '../data/htmlBuilder';
import { BeatLoader } from 'react-spinners';
import { Formik, Field } from "formik";
import { mathPrice } from '../helper';

// Input feedback
const InputFeedback = ({ error }) =>
  error ? <div className={"input-feedback"}>{error}</div> : null;


const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    ...props
  }) => {
    return (
      <div>
        <input
          name={name}
          id={id}
          type="checkbox"
          value={value}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          className="form-check-input quiz-nadlan-checkbox custom-control-input"
        />
        <label className="form-check-label  custom-control-label" htmlFor={id}>{label}</label>
        {touched[name] && <InputFeedback error={errors[name]} />}
      </div>
    );
  };

export default class Finish extends React.Component {

    state = {
        email: "",
        name: "",
        phone: "",
        subscribe: true,
        policy: true,
        sendEmail: false,
        sending: false,
        stepFinish: 0
    }

    handlerChangeInput = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    incrementStep = (values) => {
        const { answersResult } = this.props;
        
        const price = mathPrice(answersResult);

        this.setState({stepFinish: 1});
        const htmlTemp = encodeURIComponent(buildHtmlData(answersResult, price, values.name, true));
        fetch('/wp-admin/admin-ajax.php', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `action=bbc_price&html=${htmlTemp}&email=${values.email}&name=${values.name}&phone=${values.phone}&temporary=true`
        })
    }


    handlerSendEmail = (values) => {
        const { subscribe, policy } = this.state;
        const { answersResult } = this.props;

        const price = mathPrice(answersResult);
        const html = encodeURIComponent(buildHtmlData(answersResult, price, values.name, false));
        this.setState({sendEmail: true});
        
        const testSub = subscribe ? "YES" : "NO"; 

        fetch('/wp-admin/admin-ajax.php', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `action=bbc_price&html=${html}&email=${values.email}&name=${values.name}&phone=${values.phone}&subscribe=${testSub}&policy=${policy}&temporary=false`
        })
        .then(response => { 
            return response.json();
        }).then(result => {
            result.success && this.setState({
                sending: result.success,
                name: "",
                email: "",
                phone: "",
                subscribe: true,
                policy: true
            });
            this.props.handlerStep(100);
            this.props.handlerClearAll();
        }
          ).catch(error => this.setState({ error, isLoading: false, sendEmail: false }));
    }

    renderLoading = () => {
        return (
        <span className='sweet-loading'>
            <BeatLoader
                sizeUnit={"px"}
                size={15}
                color={'#123abc'}
                loading={this.state.loading}
            />

        </span> );
    }

    handleClickSubscribe = () => {
        this.setState({
            subscribe: !this.state.subscribe
          });
    }

    handleClickPolicy = () => {
        this.setState({
            policy: !this.state.policy
          });
    }

    renderInput = (props) => {
        return (
            <>
             <p className="text-center">
             השאירו בבקשה כתובת דוא״ל ותוך דקה הדו״ח אצלכם במייל
            </p> 
            <hr className="quiz-nadlan-hr"/>
           
            <div className="form-group">
                <input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="mb-2 form-control quiz-nadlan-input"
                    value={props.values.name}
                    placeholder="שם מלא*"
                    name="name"
                />
                {props.errors.name && props.touched.name && <div className="invalid-feed">{props.errors.name}</div>}
                <input
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder="מייל*"
                    value={props.values.email}
                    className="mb-2 form-control quiz-nadlan-input"
                    name="email"
                />
                {props.errors.email && props.touched.email &&  <div className="invalid-feed">{props.errors.email}</div>}
                <input
                    type="text"
                    className="mb-2 form-control quiz-nadlan-input"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder="טלפון נייד*" 
                    value={props.values.phone}
                    name="phone"
                />
                {props.errors.phone && props.touched.phone && <div className="invalid-feed">{props.errors.phone}</div>}
            </div>
            <div className="mb-2 pb-4">
                    {/* <div className="col-md-6 text-center" style={{lineHeight: "40px"}}>   
                        <div onClick={() => this.props.handlerPrev()} className="quiz-nadlan-link">חזור לשאלון</div>
                    </div> */}
                    <button type="button" onClick={()=>this.incrementStep(props.values)}  className="form-control col-md-12 quiz-nadlan-button-form quiz-nadlan-button-send quiz-nadlan-button-green" disabled={(!props.values.name || props.errors.name || props.errors.phone || props.errors.email)}>      
                    שלחו לי       
                    </button>
                </div>
            </>);
    }

    

    renderCheckbox = (props) => {
        return (
            <>
                <div className="form-group">       
                    <div className="form-check  custom-control custom-checkbox">
                        <Field
                            component={Checkbox}
                            name="subscribe"
                            id="subscribe"
                            label="אין לי התנגדות לקבל פעם בחודש newsletter עם המציאות בתחום המשרדים"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-check form-check-policy custom-control custom-checkbox">
                    <Field
                            component={Checkbox}
                            name="policy"
                            id="policy"
                            label='אני מאשר ויודע ש-PA של חברת RECOM ו-BBC נדל"ן מספק דו"ח מחיר משוער בלבד, ואין להסתמך על דו"ח זה כמחייב. הדו"ח הינו לבדיקה ולמחשבה בלבד. '
                        />
                    </div>
                </div>
                <div className="mb-2  pb-4">
                    <button type="submit"  className="form-control col-md-12 quiz-nadlan-button-form quiz-nadlan-button-send quiz-nadlan-button-green" disabled={!props.isValid}>
                        {this.state.sendEmail && this.renderLoading()}                        
                        מאשר ומבקש לשלוח דו"ח
                    </button>
                </div>
            </>
        );
    }

    renderForm = () => (
        <Formik
            initialValues={{ 
            name: "",
            email: "",
            phone: "",
            subscribe: true,
            policy: true
             }}

             
             validate ={(values) => {
                let regex = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
                let errors = {};
                if(!values.name) {
                    errors.name = 'שדה חובה';
                }
               
                if (!values.email) {
                  errors.email = 'שדה חובה';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                  errors.email = 'כתובת דוא"ל לא חוקית';
                }
              
                if(!values.phone) {
                    errors.phone = 'שדה חובה';
                } else if (!regex.test(values.phone)) {
                    errors.phone = "טעות בטלפון מספר"
                }

                if(!values.policy) {
                    errors.police = 'שדה חובה'
                }
              
                return errors;
              }}
              
        
        onSubmit={(values, actions) => {
            this.handlerSendEmail(values);
            actions.setSubmitting(false);
        }}
          
        render={props => (
                    <form  onSubmit={props.handleSubmit}>
                        {this.state.stepFinish === 0 ? this.renderInput(props) : this.renderCheckbox(props) } 
                    </form>
                )}
        />
    );

    renderSend = () => {
        return (
            <>
                <h2  className="quiz-nadlan-header">תודה רבה שהשתמשתם ב- <br/>Price Analyzer
                </h2>    
                <hr className="quiz-nadlan-hr" />
                {this.renderForm()}
            </>
        );
    }

    renderFinal = () => {
        return (
            <>
            <h2  className="quiz-nadlan-header">
                הדו”ח של PA נשלח.<br/>
                שיהיה בהצלחה!   
            </h2>    
                
            <hr className="quiz-nadlan-hr"/>
            
            <div className="quiz-nadlan-congraText">
            צוות של RECOM ושל BBC נדל"ן תמיד לשירותכם. <br/>
            <p align="center" className="mt-3 share-button-group">
                רוצים לבדוק עוד עסקה? 
                <button onClick={this.props.onClear} className="form-control col-md-6 quiz-nadlan-button-form quiz-nadlan-button-send quiz-nadlan-button-green mt-2">PRICE ANALYZER</button>
            </p>
            <p align="center" className="mt-3  share-button-group">
                רוצים לעזור לחברים?
                <a href="mailto:?subject=אני בדקתי מחיר של המשרד עם PriceAnalyzer של חברת RECOM, ממליץ בחום !&body=
                שלום רב, %0D
                כאן לינק ל‎ Price Analyzer של חברת‎  RECOM. %0D
                זה כלי ייחודי שעזר לי מאוד לבדוק את המחיר השכרה/מכירה של המשרדים. %0D
                https://recom.co.il/ %0D
                אם יש שאלות, פנה לחברת RECOM בטלפון: %0D
                03-6965533 %0D
                בכבוד רב." className="form-control col-md-6 quiz-nadlan-button-form quiz-nadlan-button-send quiz-nadlan-button-green quiz-nadlan-button-share mt-2">שיתוף PA במייל</a>
                                <a  href="whatsapp://send?text=היי, כאן לינק לPrice  Analyzer של חברת RECOM.  
                זה כלי ייחודי שעזר לי מאוד לבדוק את העסקה בתחום המשרדים. 
                https://recom.co.il/ 
                אם יש שאלות, פנה לחברת RECOM בטלפון:  
                03-6965533 "  className="form-control col-md-6 quiz-nadlan-button-form d-sm-none d-md-none quiz-nadlan-button-send  quiz-nadlan-button-share quiz-nadlan-button-green mt-2">שיתוף DA  בWhatsApp</a>

            </p>
            רוצים לבדוק עוד דברים ולהתייעץ?
            <br/>
            התקשרו והתייעלו
            <br/>
            <a href="tel:036965533" className="h3">6965533–03</a>
            </div>
            </>
        );
    }

    render() {
        const { sending } = this.state;

        return (
            <div className="quiz-nadlan-wrapper quiz-nadlan-start"> 
                {sending ? this.renderFinal() : this.renderSend()}
            </div>
        );
    }
}