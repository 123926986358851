import bg from '../data/images/bg.jpg';

const COLOR = {
    active: '#ffffff',
    white: '#ffffff',
    noActive: "#bcc3d0",
    green: "#4bc65c",
    orange: "#ea8831"
}

export const quizNadlan = {
    direction: "rtl",
    maxWidth: "650px",
    margin: "0 auto",
    padding: "20px",
    background: bg,
    color: "#000",
    fontSize: "16px"
}

export const wrapperNadlan = {
    backgroundImage: `url(${bg})`,
    backgroundSize: "100%"
}

export const wrapperContent = {
    background: "rgba(255, 255, 255, 0.6)",
    borderRadius: "40px",
    paddingBottom: "40px",
    paddingTop: "20px",
    paddingLeft: "100px",
    paddingRight: "100px",

}

export const header = {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#000000",
    textAlign: "right",
    paddingLeft : '15px',
    paddingRight : '15px',
}

export const hr = {
    background: "#fff",
    borderBottom: 0
}

export const list = {
    paddingLeft : '15px',
    paddingRight : '15px',
    margin: 0,
    marginTop: "20px",
    listStyle: 'none'
}

export const circle = (status) => ({
    borderRadius: '50%',
    display: "inline-block",
    width: '20px',
    height: '20px',
    background: COLOR[status]
});

export const question = (status) => ({
    background: COLOR[status],
    marginTop: "10px",
    marginBottom: "10px",
    padding: "15px 40px",
    borderRadius: "25px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "initial"
});

export const button = (type) => ({
    background: COLOR[type],
    marginTop: "15px",
    marginBottom: "15px",
    color: COLOR.white,
    fontSize: "20px",
    padding: "10px 30px",
    border: 0,
    width: "280px",
    textAlign: "center",
    borderRadius: "25px",
    boxShadow: "0 3px 5px #0000005c"
});

export const buttonWrapper = {
    display: "flex",
    justifyContent: "space-between"
}

export const fotsWrapper = {
   ...buttonWrapper,
   marginTop: "10px",
   marginBottom: "30px",
   marginRight: "10px",
   marginLeft: "50px",
   color: "#fff",
   alignItems: "center"

}

