import { questions } from "../data/question";
import { findAnswerBySlug, findQuestionBySlug } from "../helper";

const buildQuestion = (answersResult, priceSum, temporary=false) => {
    const {result, rent, build} = answersResult;
    let resultHtml = "";
    if(temporary) {
        resultHtml += `<div><strong>מחיר של איזו עסקה אתה מעוניין לבדוק? </strong><br><br>${rent === "true" ? "השכרה" : "מכירה/רכישה"}`;
        resultHtml += `<br/></div>`;
        resultHtml += `<div><strong>מחיר של איזו עסקה אתה מעוניין לבדוק?</strong><br><br>${build.name}`;
        resultHtml += `<br/></div>`;
             
        for (let i = 0; i<result.length; i++){

            const slug = result[i].slug;
            let question = findQuestionBySlug(questions, result[i].slug);
            let answers = question.answers;
            // let { question, slug } = questions[i];
            let answer = answers[result[i].answer]
            if(slug === "brutoNetto") {
                if(findAnswerBySlug(result, 'size') < 2) {
                    answer = answers[0][result[i].answer];
                } else {
                    answer = answers[1][result[i].answer];
                }
            }
    
            if(slug === "finishLevel") {
                if(findAnswerBySlug(result, 'size') < 2) {
                    answer = answers[0][result[i].answer];
                } else {
                    answer = answers[1][result[i].answer];
                }
            }
         
                 if (result[i] !== null && result[i] !== undefined){
                    resultHtml += `<div><strong>${i+1}. ${question.question}</strong><br><br>${answer}`;
                    resultHtml += `<br/></div>`;
                }
        }
    } else {

                resultHtml += `<div>`;
                resultHtml += `
                <table style="text-align: center;" align="center">
                    <tr  style="text-align:center;">    
                        <td class="header" style="text-align: center;" align="center">
                            <h2>
                                תודה רבה שהשתמשתם ב-Analyzer Price  של חברת RECOM<br/>
                                להלן דו"ח עם המחיר המוערך של המשרד שביקשת לבדוק.<br/>
                                המחיר המבוקש  (בתוספת מע"מ)
                            </h2>
                           ${build && build.descriptionStart ? `<h2>${build.descriptionStart}</h2>` : ""} 
                            ${priceSum}       
                            <h3>
                                <strong>
                                    לכל שאלה וייעוץ פנו אלינו. <br/>
                                    תמיד נשמח לעזור ולתת לכם שירות VIP <br/>
                                    03-6965533<br/>
                                    office@recom.co.il
                                </strong>
                            </h3>
                        </td>
                    </tr>
                </table>
                <br/></div>`;
    }
    return resultHtml;
}

 

export const buildHtmlData = (answers, price, name, temporary=false) => {
    let priceSum;
    if(answers.rent === "true") {
        priceSum = `${price}`
    } else {
        priceSum = `${price}`
    }

    return `
    <html dir="rtl">
        <head>
            <style>
                td.header {
                    vertical-align: middle;
                    text-align:center;
                    width:100%;
                }
            </style>
        </head>    
        <body>
            ${buildQuestion(answers, priceSum, temporary)}
        </body>
    </html>
    `;
};