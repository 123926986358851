export const localStoragesKeys = {
    step: "step_price",
    result: "result_price",
    startTime: "startTime_price",
    wizard: "wizard_price",
    build: "build_price",
    rent: "rent_price"
}

export const generateHebrewFromNumber = (number) => {
    switch (number) {
        case 0:
            return "א";
        case 1:
            return "ב";
        case 2:
            return "ג";
        case 3:
            return "ד";
        case 4:
            return "ה";
        case 5:
            return "ד";
        default:
            return "a";
    }
}

const getBasePrice = (result, rentType, build, confPrice = 0) => {
    if (rentType === "true") {
        return build.rent_price[result] - build.rent_price[result] * (confPrice / 100);
    }
    return build.sale_price[result]  - build.rent_price[result] * (confPrice / 100);

}


const libraryConf = {
    1: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [1000, 1000, 1000, 1000],
            view: [500, 500, 700, 700],
            balcony: [1000, 700, 500, 500],
            casing: [3500, 3500, 3500, 3500],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 3, 3],
            etage: [2, 2, 2, 2],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [30, 30, 30, 30],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    2: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [5000, 3000, 1500, 750],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [1000, 1000, 1000, 1000],
            view: [500, 500, 700, 700],
            balcony: [1000, 700, 500, 500],
            casing: [3500, 3500, 3500, 3700],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [50, 30, 7, 4],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 2, 3],
            etage: [2, 2, 2, 2],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [30, 30, 30, 30],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    3: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [5000, 3000, 1500, 750],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [1000, 1000, 1000, 1000],
            view: [500, 500, 700, 700],
            balcony: [1000, 700, 500, 500],
            casing: [3500, 3500, 3500, 3700],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [50, 30, 7, 4],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 2, 3],
            etage: [2, 2, 2, 2],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [30, 30, 30, 30],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    4: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [2000, 1000, 600, 500],
            wc: [2000, 1000, 600, 500],
            enter: [500, 500, 1000, 1000],
            etage: [1000, 1000, 1000, 1000],
            view: [500, 500, 700, 500],
            balcony: [500, 500, 500, 500],
            casing: [3300, 3300, 3300, 3300],
            countRoom: [500, 400, 500, 400],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [50, 30, 4, 3],
            wc: [5, 5, 4, 3],
            enter: [2, 3, 3, 3],
            etage: [2, 2, 2, 2],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [38, 38, 38, 38],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    5: {
        sale: 0,
        rent: 0
    },
    6: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [5000, 3000, 1500, 1000],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [0, 0, 0, 0],
            view: [500, 500, 700, 700],
            balcony: [1000, 500, 500, 500],
            casing: [3200, 3200, 3200, 3200],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [50, 30, 10, 7],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 3, 3],
            etage: [0, 0, 0, 0],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [30, 30, 30, 30],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    7: {
        sale: 0,
        rent: 0
    },
    8: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [1000, 1000, 1000, 1000],
            view: [500, 500, 700, 700],
            balcony: [1000, 700, 500, 500],
            casing: [3500, 3500, 3500, 3500],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 3, 3],
            etage: [2, 2, 2, 2],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [30, 30, 30, 30],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    9: {
        sale: 0,
        rent: 0
    },
    10: {
        sale: 0,
        rent: 0
    },
    11: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [5000, 3000, 1500, 1000],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [0, 0, 0, 0],
            view: [500, 500, 700, 700],
            balcony: [1000, 500, 500, 500],
            casing: [3200, 3200, 3200, 3200],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [50, 30, 10, 7],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 3, 3],
            etage: [0, 0, 0, 0],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [30, 30, 30, 30],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    12: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [5000, 3000, 1500, 1000],
            wc: [0, 0, 0, 0],
            enter: [500, 500, 1000, 1000],
            etage: [0, 0, 0, 0],
            view: [500, 500, 700, 700],
            balcony: [1000, 700, 500, 500],
            casing: [3300, 3300, 3300, 3300],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [50, 30, 10, 7],
            wc: [0, 0, 0, 0],
            enter: [2, 3, 3, 3],
            etage: [0, 0, 0, 0],
            view: [2, 2, 2, 2],
            balcony: [2, 2, 2, 2],
            casing: [38, 38, 38, 38],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    13: {
        sale: 0,
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [0, 0, 0, 0],
            wc: [5, 5, 5, 5],
            enter: [0, 0, 0, 0],
            etage: [0, 0, 0, 0],
            view: [0, 0, 0, 0],
            balcony: [2, 2, 2, 2],
            casing: [33, 33, 33, 33],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    14: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [0, 0, 0, 0],
            etage: [0, 0, 0, 0],
            view: [0, 0, 0, 0],
            balcony: [0, 0, 0, 0],
            casing: [3700, 3700, 3700, 3700],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [0, 0, 0, 0],
            etage: [0, 0, 0, 0],
            view: [0, 0, 0, 0],
            balcony: [0, 0, 0, 0],
            casing: [33, 33, 33, 33],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
    15: {
        sale: {
            barNeto: [500, 500, 500, 500],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [0, 0, 0, 0],
            etage: [0, 0, 0, 0],
            view: [0, 0, 0, 0],
            balcony: [0, 0, 0, 0],
            casing: [3700, 3700, 3700, 3700],
            countRoom: [500, 400, 500, 500],
            finishLevel: [1000, 750, 750, 750],
            furnished: [300, 300, 200, 200]
        },
        rent: {
            barNeto: [2, 2, 2, 3],
            mmk: [0, 0, 0, 0],
            wc: [0, 0, 0, 0],
            enter: [0, 0, 0, 0],
            etage: [0, 0, 0, 0],
            view: [0, 0, 0, 0],
            balcony: [0, 0, 0, 0],
            casing: [33, 33, 33, 33],
            countRoom: [3, 3, 3, 3],
            finishLevel: [5, 5, 5, 5],
            furnished: [5, 5, 5, 5]
        }
    },
}

const answerKey = [
    'size', 'barNeto', 'mmk', 'wc', 'enter', 'etage', 'view', 'balcony', 'casing', 'countRoom', 'finishLevel', 'furnished'
]

const createAnswerValueArray = (result) => {
    let res = {};
    result.map( r => res[r.slug] = r.answer);
    return res;
}

const getAnswersFinal = (result) => {
    let res = {};
    const arrAnswer = createAnswerValueArray(result);
    answerKey.map( aKey => {
        const answer = Number.isInteger( arrAnswer[aKey] ) ? arrAnswer[aKey] : 0;
        return res[aKey] = answer;
    });
    return res;
}

export const mathPrice = ({
    result,
    rent,
    build
}) => {
    let resPrice, res = "";
    const answerArray = getAnswersFinal(result);
    const basePrice = getBasePrice(answerArray.size, rent, build, 15);
    const size = answerArray.size;
    const barNeto = answerArray.barNeto;
    const mmk = answerArray.mmk;
    const wc = answerArray.wc;
    const enter = answerArray.enter;
    const etage = answerArray.etage;
    const view = answerArray.view;
    const balcony = answerArray.balcony ? 1 : 0;
    const casing = answerArray.casing;
    let countRoom = answerArray.countRoom ? 1 : 0;
    let finishLevel = answerArray.finishLevel;
    let furnished = answerArray.furnished;

    if (casing) {
        countRoom = 0;
        finishLevel = 0;
        furnished = 0;
    }
    if (rent === "true") {
        if (build.description) {
            let buildPrice = build.description;
            resPrice = `<h1><span dir="ltr" class="eng"  style="padding:0; margin: 0 auto; direction: ltr;">${buildPrice}</span> ₪ למ"ר</h1><br/>` + build.options;
        } else { 
            res = basePrice - (furnished * libraryConf[build.slug].rent.furnished[size] + barNeto * libraryConf[build.slug].rent.barNeto[size] + mmk * libraryConf[build.slug].rent.mmk[size] + wc * libraryConf[build.slug].rent.wc[size] + countRoom * libraryConf[build.slug].rent.countRoom[size] + finishLevel * libraryConf[build.slug].rent.finishLevel[size] + enter * libraryConf[build.slug].rent.enter[size] + etage * libraryConf[build.slug].rent.etage[size] + view * libraryConf[build.slug].rent.view[size] + balcony * libraryConf[build.slug].rent.balcony[size] + casing * libraryConf[build.slug].rent.casing[size]);
            resPrice = `<h1><span dir="ltr" class="eng"  style="padding:0; margin: 0 auto; direction: ltr;">${numberWithCommas(res-3)} - ${numberWithCommas(res+3)}</span> ₪ למ"ר</h1>`;
        }
    } else {
        res = basePrice - (furnished * libraryConf[build.slug].sale.furnished[size] + barNeto * libraryConf[build.slug].sale.barNeto[size] + mmk * libraryConf[build.slug].sale.mmk[size] + wc * libraryConf[build.slug].sale.wc[size] + countRoom * libraryConf[build.slug].sale.countRoom[size] + finishLevel * libraryConf[build.slug].sale.finishLevel[size] + enter * libraryConf[build.slug].sale.enter[size] + etage * libraryConf[build.slug].sale.etage[size] + view * libraryConf[build.slug].sale.view[size] + balcony * libraryConf[build.slug].sale.balcony[size] + casing * libraryConf[build.slug].sale.casing[size]);
        resPrice = `<h1><span dir="ltr" class="eng"  style="padding:0; margin: 0 auto; direction: ltr;">${numberWithCommas(res-500)} - ${numberWithCommas(res+500)}</span> ₪ למ"ר</h1>`;
    }

    return resPrice;
}

export const findAnswerBySlug = (result, slug) => {
    const res = result.find( x => x.slug === slug);
    return res ? res.answer : "";
}


export const findQuestionBySlug = (questions, slug) => {
    const res = questions.find( x => x.slug === slug);
    return res ? res : "";
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}